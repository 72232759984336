<!-- Item wrapper -->
<div class="fuse-vertical-navigation-item-wrapper" [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper">

    <!-- Item with an internal link -->
    <ng-container *ngIf="item.link">
        <a class="fuse-vertical-navigation-item"
            [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}" [routerLink]="[item.link]"
            [fragment]="item.fragment ?? null" [preserveFragment]="item.preserveFragment ?? false"
            [queryParams]="item.queryParams ?? null" [queryParamsHandling]="item.queryParamsHandling ?? null"
            [routerLinkActive]="'fuse-vertical-navigation-item-active'" [routerLinkActiveOptions]="isActiveMatchOptions"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with an external link
    <ng-container *ngIf="item.link && item.externalLink && !item.function && !item.disabled">
        <a
            class="fuse-vertical-navigation-item"
            [href]="item.link"
            [target]="item.target || '_self'"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>-->
</div>

<!-- Item template -->
<ng-template #itemTemplate>

    <!-- Mat Icon -->
    <ng-container *ngIf="item.icon && item.icon_type=='mat'">
        <mat-icon class="fuse-vertical-navigation-item-icon" [ngClass]="item.classes?.icon">{{item.icon}}</mat-icon>
    </ng-container>

    <!-- Fa Icon -->
    <ng-container *ngIf="item.icon && item.icon_type!='mat'">
        <fa-icon class="nav-icon fuse-vertical-navigation-item-icon" [icon]="[item.icon_type, item.icon]"></fa-icon>
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="fuse-vertical-navigation-item-title-wrapper">
        <div class="fuse-vertical-navigation-item-title">
            <span [ngClass]="item.classes?.title">
                {{item.title}}
            </span>
        </div>
        <ng-container *ngIf="item.subtitle">
            <div class="fuse-vertical-navigation-item-subtitle">
                <span [ngClass]="item.classes?.subtitle">
                    {{item.subtitle}}
                </span>
            </div>
        </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
        <div class="fuse-vertical-navigation-item-badge">
            <div class="fuse-vertical-navigation-item-badge-content" [ngClass]="item.badge.classes">
                {{item.badge.title}}
            </div>
        </div>
    </ng-container>

</ng-template>
