import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'app/core/directives/directives.module';
import { MatIconModule } from '@angular/material/icon';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { MatDividerModule } from '@angular/material/divider';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { AbilityModule } from '@casl/angular';
import { NoAccessModule } from 'app/modules/auth/no-access/no-access.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonComponentModule } from 'app/modules/common/common-component.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PipesModule } from 'app/pipes/pipes.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    pbColor: "var(--fuse-primary)",
    fgsColor: "var(--fuse-primary)"
};

@NgModule({
    imports: [
        CommonModule,
        TranslocoModule,

        // Forms
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatInputModule,

        // Common Directives
        DirectivesModule,

        // Spinner
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),

        // Material
        MatIconModule,
        MatDividerModule,
        MatOptionModule,
        MatSelectModule,
        MatTableModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        // User access
        AbilityModule,
        NoAccessModule,

        // Pipes
        PipesModule
    ],
    exports: [
        CommonModule,
        TranslocoModule,

        // Forms
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatInputModule,

        // Common Directives
        DirectivesModule,

        // Spinner
        NgxUiLoaderModule,

        // Material
        MatIconModule,
        MatDividerModule,
        MatOptionModule,
        MatSelectModule,
        MatTableModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        // User access
        AbilityModule,
        NoAccessModule,

        // Pipes
        PipesModule
    ]
})
export class SharedModule {
}
