import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subject, takeUntil, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { HttpService } from '../common-services/http.service';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    userData: any;
    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService,
        private userService: UserService) {
        this.userService.user$.pipe(
            takeUntil(this._unsubscribeAll)).subscribe((data) => {
                this.userData = data
            })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<any> {
        return this._httpService.get('user/role/menu/getList').pipe(
            tap((navigation) => {
                this._navigation.next(navigation.data);
            })
        );
    }
}
