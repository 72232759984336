import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoAccessComponent } from './no-access.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [
        NoAccessComponent
    ],
    imports: [
        CommonModule,
        TranslocoModule
    ],
    exports: [
        NoAccessComponent
    ]
})
export class NoAccessModule { }
