import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { UpdateGuard } from './core/auth/guards/update.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', data: { title: 'Sign-in' }, loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', data: { title: 'Sign-up' }, loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
            { path: 'unlock-session/:userName', data: { title: 'Unlock session' }, loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
            {
                path: 'dXBkYXRl',
                loadChildren: () => import('app/modules/pages/update/update.module').then(m => m.UpdateModule)
            },
            {
                path: 'error', children: [
                    { path: '404', loadChildren: () => import('app/modules/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
                    { path: '500', loadChildren: () => import('app/modules/pages/error/error-500/error-500.module').then(m => m.Error500Module) },
                    { path: '503', loadChildren: () => import('app/modules/pages/error/error-503/error-503.module').then(m => m.Error500Module) },
                    { path: '612', loadChildren: () => import('app/modules/pages/error/error-612/error-612.module').then(m => m.Error612Module) }
                ]
            },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            // Dashboard
            { path: 'dashboard', data: { title: 'Dashboard' }, loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule) },

            // Customers
            { path: 'customers', data: { title: 'Customers' }, loadChildren: () => import('app/modules/admin/customers/customers.module').then(m => m.CustomersModule) },

            // Employees
            { path: 'employees', data: { title: 'Employees' }, loadChildren: () => import('app/modules/admin/employees/employees.module').then(m => m.EmployeesModule) },

            // Companies
            { path: 'company', data: { title: 'Company' }, loadChildren: () => import('app/modules/admin/company/company.module').then(m => m.CompanyModule) },

            // Loans
            { path: 'loan', data: { title: 'Loan' }, loadChildren: () => import('app/modules/admin/loans/loans.module').then(m => m.LoansModule) },

            // Loans
            { path: 'allocation', data: { title: 'Allocation' }, loadChildren: () => import('app/modules/admin/allocations/allocations.module').then(m => m.AllocationsModule) },

            // Collections
            { path: 'collection', data: { title: 'Collection' }, loadChildren: () => import('app/modules/admin/collections/collections.module').then(m => m.CollectionsModule) },

            // Bank
            { path: 'bank', data: { title: 'Bank' }, loadChildren: () => import('app/modules/admin/bank/bank.module').then(m => m.BankModule) },

            // Cases
            { path: 'cases', data: { title: 'Cases' }, loadChildren: () => import('app/modules/admin/cases/cases.module').then(m => m.CasesModule) },

            // Attributes
            { path: 'attributes', data: { title: 'Attributes' }, loadChildren: () => import('app/modules/admin/attributes/attributes.module').then(m => m.AttributesModule) },

            // Reports
            { path: 'reports', data: { title: 'Reports' }, loadChildren: () => import('app/modules/admin/reports/reports.module').then(m => m.ReportsModule) },

            // PTP
            { path: 'ptp', data: { title: 'PTP' }, loadChildren: () => import('app/modules/admin/ptp/ptp.module').then(m => m.PtpModule) },

            // Accessibility
            { path: 'accessibility', data: { title: 'Accessibility' }, loadChildren: () => import('app/modules/admin/accessibility/accessibility.module').then(m => m.AccessibilityModule) },

            // Invoice
            {
                path: 'invoice', children: [
                    {
                        path: 'printable', children: [
                            { path: 'compact', loadChildren: () => import('app/modules/pages/invoice/printable/compact/compact.module').then(m => m.CompactModule) },
                            { path: 'modern', loadChildren: () => import('app/modules/pages/invoice/printable/modern/modern.module').then(m => m.ModernModule) }
                        ]
                    }
                ]
            },

            // Settings
            { path: 'profile', data: { title: 'Profile' }, loadChildren: () => import('app/modules/pages/settings/settings.module').then(m => m.SettingsModule) },

            // Maintenance
            { path: 'maintenance', data: { title: 'Maintenance' }, loadChildren: () => import('app/modules/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule) },

            // 404 & Catch all
            { path: '404-not-found', data: { title: '404' }, pathMatch: 'full', loadChildren: () => import('app/modules/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
            { path: '**', redirectTo: '404-not-found' }
        ]
    },
    {
        path: '**',
        redirectTo: 'dashboard',
    }
];
