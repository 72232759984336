import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { FuseConfigService } from '@fuse/services/config';
import { environment } from 'environments/environment';
import { LoansService } from 'app/modules/admin/loans/loans.service';
import moment from 'moment';
import { UtilityService } from 'app/core/common-services/utility.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { AllocationsService } from 'app/modules/admin/allocations/allocations.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    isLoading: boolean;
    navigation: Navigation;
    user: any;
    recoveryTotal = 0

    orgURL = environment.orgURL
    appVersion = environment.appVersion;

    daysSelected: any[] = [];
    dates: any[] = [];
    startOfMonth = moment().startOf('month');
    endOfMonth = moment().endOf('month');

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    @ViewChild('dateMenu') dateMenu: MatMenuTrigger
    @ViewChild('calendar') calendar
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _loansService: LoansService,
        public _userService: UserService,
        private _utilityService: UtilityService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public _fuseConfigService: FuseConfigService,
        public _allocationsService: AllocationsService,
        public _translocoService: TranslocoService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: any) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: any) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this._loansService.getRecoveryTotal().then((data: any) => {
            this.recoveryTotal = data;
        })

        this.getDates()
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Date extend
     * @param event
     * @returns
     */

    isSelected = (event: any) => {
        const date = new Date(event).getDate()
        return this.daysSelected.find(x => x == date) ? "selected" : null;
    };

    select(event: any, calendar: any) {
        const date = new Date(event).getDate()
        const index = this.daysSelected.findIndex(x => x == date);
        if (index < 0) this.daysSelected.push(date);
        else this.daysSelected.splice(index, 1);
        calendar.updateTodaysDate();
    }

    addDates() {
        this.isLoading = true
        this._allocationsService.addDates({ dates: this.daysSelected }).then((data: any) => {
            this._utilityService.showSuccess(this._translocoService.translate("allocation.message.dates_extended_successfully"))
            this.getDates()
            this.dateMenu.closeMenu()
        }).finally(() => {
            this.isLoading = false
        })
    }

    getDates() {
        this._allocationsService.getDates().then((data: any) => {
            this.daysSelected = data ?? [];
            this.dates = data
            this.calendar.updateTodaysDate();
        })
    }
}
