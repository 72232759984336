import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appTabSwipe]'
})
export class TabSwipeDirective {

    @Output()
    public tabSwipe = new EventEmitter<any>();

    constructor(private el: ElementRef) {

    }

    selectedTab: number = 0;
    swipeCoord: [number, number];
    swipeTime: number;

    @HostListener('touchstart', ['$event'])
    @HostListener('touchend', ['$event'])
    onTouch(e: TouchEvent) {
        const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
        const time = new Date().getTime();
        if (e.type === 'touchstart') {
            this.swipeCoord = coord;
            this.swipeTime = time;
        } else if (e.type === 'touchend') {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;
            if (duration < 1000 //
                && Math.abs(direction[0]) > 30 // Long enough
                && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
                const swipe = direction[0] < 0 ? 'next' : 'previous';
                if (swipe === 'next') {
                    const isFirst = this.selectedTab === 0;
                    if (this.selectedTab <= 3) {
                        this.selectedTab = isFirst ? 1 : this.selectedTab + 1;
                    }
                } else if (swipe === 'previous') {
                    const isLast = this.selectedTab === 4;
                    if (this.selectedTab >= 1) {
                        this.selectedTab = this.selectedTab - 1;
                    }
                }
                this.tabSwipe.emit(this.selectedTab)
                // Do whatever you want with swipe
            }
            // }
        }
    }
}
