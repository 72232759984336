import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './click-outside.directive';
import { DragDropDirectiveDirective } from './drag-drop-directive.directive';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { TitleCaseDirective } from './title-case.directive';
import { TabSwipeDirective } from './tab-swipe.directive';



@NgModule({
    declarations: [
        ClickOutsideDirective,
        DragDropDirectiveDirective,
        OnlyNumbersDirective,
        TitleCaseDirective,
        TabSwipeDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ClickOutsideDirective,
        DragDropDirectiveDirective,
        OnlyNumbersDirective,
        TitleCaseDirective,
        TabSwipeDirective
    ]
})
export class DirectivesModule { }
