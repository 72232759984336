import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { forkJoin, from, lastValueFrom, mergeMap, Observable, of, switchMap, take } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        public _userService: UserService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    // canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //     const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    //     return this._check(redirectUrl);
    // }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    // canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    //     return this._check('/');
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): Observable<any> {
        // Check the authentication status
        return forkJoin([
            this._authService.check().pipe(
                mergeMap(async (authenticated) => {
                    // If the user is not authenticated...
                    if (!authenticated) {

                        // Redirect to the sign-in page
                        this._router.navigate(['sign-in'], { queryParams: { redirectURL } });

                        // Prevent the access
                        return of(false);
                    }

                    return of(this._userService.user$.pipe(take(1)).subscribe((data: any) => {
                        if (data.user_login?.user_role_name?.user_role_slug != 'developer') {
                            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                                if (result.state != 'granted') {

                                    this._router.navigate(['sign-in'], { queryParams: { force: true } });

                                    // Prevent the access
                                    return of(false);
                                }

                            })
                        }
                        // Allow the access
                        return of(true);
                    }))
                })

            )
        ])

    }
}
