import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findIndexArray',
    pure: false
})
export class FindIndexInArrayPipe implements PipeTransform {

    transform(array: any, ...args: any[]): any {
        let data = array.findIndex((item) => item[args[0]] == args[1])
        return data
    }

}
