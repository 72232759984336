/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'dashboard',
        link: '/dashboard'
    },
    {
        id: 'customers',
        title: 'Customers',
        type: 'basic',
        icon: 'group',
        link: '/customers'
    },
    {
        id: 'employees',
        title: 'Employees',
        type: 'basic',
        icon: 'supervisor_account',
        link: '/employees'
    },
    {
        id: 'cases',
        title: 'Cases',
        type: 'basic',
        icon: 'add_task',
        link: '/cases'
    },
    {
        id: 'attributes',
        title: 'Attributes',
        type: 'collapsable',
        icon: 'assignment',
        children: [
            {
                id: 'userRoles',
                title: 'User Roles',
                type: 'basic',
                icon: 'badge',
                link: 'attributes/user-roles'
            },
        ]
    },
    {
        id: 'accessibility',
        title: 'Accessibility',
        type: 'collapsable',
        icon: 'settings_accessibility',
        children: [
            {
                id: 'menus',
                title: 'Menus',
                type: 'basic',
                icon: 'menu',
                link: 'accessibility/menus'
            },
        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
