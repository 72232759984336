import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../common-services/utility.service';
import { environment } from 'environments/environment';
import { compareVersions } from 'compare-versions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _toastr: ToastrService,
        private _utility: UtilityService,
        private _router: Router,
    ) {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();
        const url = window.location.pathname === '/sign-in' ? '/' : window.location.pathname;

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if (this._authService.accessToken) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken.token)
            });
        }

        // Response
        return next.handle(newReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.body.success == 1) {
                    if (event.body.fe_version && compareVersions((environment.appVersion.split('-')[0]), event.body.fe_version) == -1)
                        this._router.navigate(['/dXBkYXRl'], { replaceUrl: true, skipLocationChange: true })
                }
                if (event instanceof HttpResponse && event.body.success == 0) {
                    switch (event.body.status_code) {
                        case 401:
                            this._toastr.error('Unauthorized access');
                            this._utility.clearSession()
                            this._router.navigate(['sign-in'], { queryParams: { redirectURL: url } })
                            break;
                        case 500: // INTERNAL_SERVER_ERROR
                            this._toastr.error(event.body.message);
                            break;
                        case 601: // USERNAME_NOT_VERIFIED
                        case 604: // USER_NOT_VERIFIED
                        case 605: // USER_BLOCKED
                        case 606: // EMAIL_ALREADY_VERIFIED
                        case 607: // EMAIL_NOT_VERIFIED
                        case 609: // NO ACCESS
                        case 652: // NO ACCOUNT EXIST
                            break;
                        case 611: // INVALID URL
                            this._toastr.error(event.body.message);
                            break;
                        case 650: // SYSTEM_DOWN
                            this.redirectTo('/error/500')
                            break;
                        case 503: // SYSTEM UNAVAILABLE
                            this.redirectTo('/error/503')
                            break;
                        case 612: // DESIGNATION BLOCKED
                            this.redirectTo('/error/612')
                            break;
                        case 200: // OK
                            return event;
                        case 608: // VALIDATION_ERROR
                        case 603: // OTP_RESEND_LIMIT_REACHED
                        case 400: // REQUEST CAN'T BE COMPLETED
                            this._toastr.error(event.body.message);
                            return event;
                        default:
                            this._toastr.error('Unknown error');
                    }
                }

                return event;
            }),
            catchError((error) => {

                if (error.name.toString() == 'TimeoutError') {
                    this._toastr.warning('Request Time out!');
                }
                else {
                    switch (error.status) {
                        case 404:
                            this._toastr.error('Service not found');
                            break;
                        case 401:
                            this._toastr.error('Unauthorized access');
                            this._utility.clearSession()
                            break;
                        case 429: // NO ACCESS
                            this._toastr.error("Too many requests");
                            break;
                        case 500:
                            console.log(error);
                            this._toastr.error('Internal server error');
                            break;
                        default:
                            this._toastr.error('Unknown error');
                    }
                }
                //this.redirectTo('/error/' + error.status + '?ref=' + url)
                console.log("Error while calling " + error.url)
                return throwError({ message: error.statusText });
            })
        );
    }
    redirectTo(url) {
        const tree: UrlTree = this._router.parseUrl(window.location.pathname);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g.segments;
        if (s[0].path != 'error')
            this._router.navigateByUrl(url, { replaceUrl: true, skipLocationChange: true })
    }

    isNewerVersion(oldVer, newVer) {
        var a = oldVer.split('.');
        var b = newVer.split('.');

        for (var i = 0; i < a.length; ++i) {
            a[i] = Number(a[i]);
        }
        for (var i = 0; i < b.length; ++i) {
            b[i] = Number(b[i]);
        }
        if (a.length == 2) {
            a[2] = 0;
        }

        if (a[0] > b[0]) return true;
        if (a[0] < b[0]) return false;

        if (a[1] > b[1]) return true;
        if (a[1] < b[1]) return false;

        if (a[2] > b[2]) return true;
        if (a[2] < b[2]) return false;

        return true;
    }
}
