import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { HttpService } from '../common-services/http.service';
import { Ability, AbilityBuilder } from '@casl/ability';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private userData;
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService,
        private ability: Ability) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: any) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<any> {
        return this._httpService.get('user/getProfile').pipe(
            tap((user) => {
                this.userData = user.data
                this._user.next(user.data);
            })
        );
    }

    /**
     * Get the current logged in user data
     */
    update(): Observable<any> {
        return this._httpService.get('user/updateProfile').pipe(
            tap((user) => {
                this._user.next(user.data);
            })
        );
    }

    /**
     * Menu access
     * @returns
     */
    getMenuAccess() {
        return new Promise((resolve, reject) => {
            this._httpService.get('user/role/menu/accessList?user_role_key=' + this.userData?.user_login?.user_role_name?.user_role_key).subscribe((data: any) => {
                this.updateAbility(data.data)
                resolve(data.data);
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Set access rules
     * @param data
     */
    private updateAbility(data) {
        const { can, cannot, rules } = new AbilityBuilder(Ability);
        if (data?.menu_list) {
            data.menu_list.forEach(menu => {
                //can('manage', menu.id)
                if (menu.is_selected == 0) {
                    cannot('read', menu.id)
                }
                else if (menu.access_level == 0) {
                    can('read', menu.id)
                }
                else if (menu.access_level == 1) {
                    can('manage', menu.id)
                }
                menu.children_menu.forEach(submenu => {
                    //can('manage', submenu.id)
                    if (submenu.is_selected == 0) {
                        cannot('read', submenu.id)
                    }
                    else if (submenu.access_level == 0) {
                        can('read', submenu.id)
                    }
                    else if (submenu.access_level == 1) {
                        can('manage', submenu.id)
                    }
                });
            });
        }
        this.ability.update(rules);
    }
}
