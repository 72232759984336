import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/common-services/http.service';
import { UtilityService } from 'app/core/common-services/utility.service';

@Injectable({
    providedIn: 'root'
})
export class LoansService {

    constructor(private _httpService: HttpService,
        private _utility: UtilityService) { }

    /**
     * Get companies tabs
     */
    getProductTabs() {
        return new Promise((resolve, reject) => {
            this._httpService.get('company/getTabs').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get open loans companies tabs
     */
    getOpenLoansCompanyTabs() {
        return new Promise((resolve, reject) => {
            this._httpService.get('company/open/getTabs').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loans
     */
    getLoans(page, companyKey, bucket, keyword?, limit?) {
        let params = new HttpParams();
        params = params.set('page', page);
        params = params.set('company_key', companyKey);
        if (limit)
            params = params.set('limit', limit);
        if (keyword)
            params = params.set('search_key', keyword);
        if (bucket)
            params = params.set('bucket_key', bucket);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/getAll?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get open loans
     */
    getOpenLoans(page, companyKey, bucket, keyword?, limit?) {
        let params = new HttpParams();
        params = params.set('page', page);
        if (companyKey)
            params = params.set('company_key', companyKey);
        if (limit)
            params = params.set('limit', limit);
        if (keyword)
            params = params.set('search_key', keyword);
        if (bucket)
            params = params.set('bucket_key', bucket);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/open/getAll?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan payments
     */
    getLoanPayments(page, loanKey, keyword?, limit?) {
        let params = new HttpParams();
        params = params.set('page', page);
        params = params.set('loan_key', loanKey);
        if (limit)
            params = params.set('limit', limit);
        if (keyword)
            params = params.set('search_key', keyword);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/payment/getByLoan?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan by id
     */
    getLoansById(key) {
        let params = new HttpParams();
        params = params.set('loan_key', key);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/getByID?' + params).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan basic by id
     */
    getLoanBasicById(key) {
        let params = new HttpParams();
        params = params.set('loan_key', key);
        params = params.set('is_basic', 1);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/getByID?' + params).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan details by id
     */
    getLoanDetailsById(key) {
        let params = new HttpParams();
        params = params.set('other_details_key', key);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/other/getByID?' + params).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan payment by id
     */
    getLoanPaymentById(key) {
        let params = new HttpParams();
        params = params.set('loan_payment_key', key);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/payment/getByID?' + params).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan vehicle by id
     */
    getLoanVehicleById(key) {
        let params = new HttpParams();
        params = params.set('loan_vehicle_key', key);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/vehicle/getByID?' + params).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan customer by id
     */
    getLoanCustomerById(key) {
        let params = new HttpParams();
        params = params.set('loan_customer_key', key);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/customer/getByID?' + params).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan customer reference by id
     */
    getLoanCustomerReferenceById(key) {
        let params = new HttpParams();
        params = params.set('loan_customer_reference_key', key);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/customer/reference/getByID?' + params).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get suppliers list
     */
    getSuppliersList() {
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/vehicle/supplier/getAll').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get payment status list
     * @returns
     */
    getPaymentStatusList() {
        return new Promise((resolve, reject) => {
            this._httpService.get('attribute/loan/payment/status/getAll?is_list=1').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get recovery total
     * @returns
     */
    getRecoveryTotal() {
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/recovery/getTotal').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan feedbacks
     */
    getLoanFeedbacks(page, loanKey, paymentKey, isStarred, keyword?, limit?) {
        let params = new HttpParams();
        params = params.set('page', page);
        params = params.set('loan_key', loanKey);
        if (paymentKey)
            params = params.set('loan_payment_key', paymentKey);
        if (limit)
            params = params.set('limit', limit);
        if (keyword)
            params = params.set('search_key', keyword);
        if (isStarred || isStarred == 0)
            params = params.set('is_starred', isStarred);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/recovery/feedback/getAll?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan recovery logs
     */
    getLoanRecoveryLogs(page, loanKey, keyword?, limit?) {
        let params = new HttpParams();
        params = params.set('page', page);
        params = params.set('loan_recovery_key', loanKey);
        if (limit)
            params = params.set('limit', limit);
        if (keyword)
            params = params.set('search_key', keyword);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/recovery/collection/log/getAll?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan recoveries
     */
    getLoanRecoveries(page, loanKey, userKey, paymentMethod, paymentType, from, to, isCompleted, companykey, keyword?, limit?) {
        let params = new HttpParams();
        params = params.set('page', page);
        params = params.set('loan_key', loanKey);
        if (limit)
            params = params.set('limit', limit);
        if (keyword)
            params = params.set('search_key', keyword);
        if (userKey)
            params = params.set('user_details_key', userKey);
        if (paymentMethod)
            params = params.set('payment_method_key', paymentMethod);
        if (paymentType)
            params = params.set('payment_type_key', paymentType);
        if (from)
            params = params.set('from', from);
        if (to)
            params = params.set('to', to);
        if (isCompleted != null)
            params = params.set('is_completed', isCompleted);
        if (companykey)
            params = params.set('company_key', companykey);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/recovery/getAll?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan recovery by id
     */
    getLoanRecoveryById(recoveryKey) {
        let params = new HttpParams();
        params = params.set('loan_recovery_key', recoveryKey);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/recovery/getByID?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get payment type list
     */
    getPaymentTypeList() {
        return new Promise((resolve, reject) => {
            this._httpService.get('attribute/payment/type/getAll?is_list=1').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get payment method list
     */
    getPaymentMethodList() {
        return new Promise((resolve, reject) => {
            this._httpService.get('attribute/payment/method/getAll?is_list=1').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get current qr
     */
    getCurrentQR() {
        return new Promise((resolve, reject) => {
            this._httpService.get('attribute/payment/qr/getCurrent?').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get visit preference list
     */
    getVisitPreferenceList() {
        return new Promise((resolve, reject) => {
            this._httpService.get('attribute/visit/preference/getAll?is_list=1').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get visit projection list
     */
    getVisitProjectionList() {
        return new Promise((resolve, reject) => {
            this._httpService.get('attribute/visit/projection/getAll?is_list=1').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get no available reason list
     */
    getNoAvailableReasonList() {
        return new Promise((resolve, reject) => {
            this._httpService.get('attribute/reason/no_available/getAll?is_list=1').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get Fo list
     */
    getFieldOfficerList() {
        return new Promise((resolve, reject) => {
            this._httpService.get('user/getAll?is_list=1').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Import loans
     * @param data
     * @returns
     */
    importLoans(data) {
        let formData = new FormData()
        this._utility.toFormData(data, formData);
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/import/importAll', formData).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Verify loans
     * @param data
     * @returns
     */
    verifyLoans(data) {
        let formData = new FormData()
        this._utility.toFormData(data, formData);
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/import/verifyLoans', formData).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Download sample
     * @param data
     * @returns
     */
    downloadSample() {
        return new Promise((resolve, reject) => {
            this._httpService.downloadGet('loan/import/getFormat').subscribe((data: any) => {
                resolve(data)
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Loan status change
     */
    changeLoanStatus(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/changeStatus', { 'loan_key': data }).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data.data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Loan basic update
     */
    updateLoanBasic(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/updateLoan', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    this._utility.showSuccess(data.message)
                    resolve(data.data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Loan details update
     */
    updateLoanDetails(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/other/updateDetails', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    this._utility.showSuccess(data.message)
                    resolve(data.data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Loan company update
     */
    updateCompany(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/updateCompany', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    this._utility.showSuccess(data.message)
                    resolve(data.data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Loan payment update
     */
    updatePaymentDetails(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/payment/updatePayment', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    this._utility.showSuccess(data.message)
                    resolve(data.data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Loan vehicle update
     */
    updateVehicleDetails(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/vehicle/updateVehicle', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    this._utility.showSuccess(data.message)
                    resolve(data.data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Loan customer update
     */
    updateCustomerDetails(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/customer/updateCustomer', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    this._utility.showSuccess(data.message)
                    resolve(data.data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Loan customer reference update
     */
    updateCustomerReferenceDetails(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/customer/reference/updateReference', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    this._utility.showSuccess(data.message)
                    resolve(data.data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Loan delete
     */
    deleteLoan(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/deleteLoan', { 'loan_key': data }).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data.data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Allocation loans
     * @param data
     * @returns
     */
    allocationsLoans(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('allocation/addAllocation', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data.data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }
    /**
     * Re allocation loans
     * @param data
     * @returns
     */
    reAllocationsLoans(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('allocation/reAllocate', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data.data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
   * Delete  allocation
   * @param data
   * @returns
   */
    removeLoanAllocationDetails(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('allocation/deleteAllocation', { 'allocation_key': data }).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }


    /**
     * Add feedback
     * @param data
     * @returns
     */
    addFeedback(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/recovery/feedback/addFeedback', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Add feedback payment
     * @param data
     * @returns
     */
    addFeedbackPayment(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/recovery/addRecovery', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Edit feedback payment
     * @param data
     * @returns
     */
    editFeedbackPayment(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/recovery/updateRecovery', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Feedback starred change
     */
    changeFeedbackStar(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/recovery/feedback/markStar', { 'loan_recovery_feedback_key': data }).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data.data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Get loan feedback by id
     */
    getFeedbackById(key) {
        let params = new HttpParams();
        params = params.set('loan_recovery_feedback_key', key);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/recovery/feedback/getByID?' + params).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loan recovery by id
     */
    getRecoveryById(key) {
        let params = new HttpParams();
        params = params.set('loan_recovery_key', key);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/recovery/getByID?' + params).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }


    /**
     * Delete recovery
     * @param data
     * @returns
     */
    removeRecovery(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/recovery/cancelRecovery', { 'loan_recovery_key': data }).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

}
