import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { IdleSessionModule } from '@fuse/services/idle-session/idle-session.module';
import { AuthModule } from 'app/core/auth/auth.module';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { ToastrModule } from 'ngx-toastr';
import { FcmMessagingService } from './messaging/fcm-messaging.service';

@NgModule({
    imports: [
        AuthModule,
        IconsModule,
        HttpClientModule,
        TranslocoCoreModule,
        IdleSessionModule,
        ToastrModule.forRoot({ preventDuplicates: true }),
    ],
    providers: [
        //FcmMessagingService,
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        }
    ]
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
