import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { CountdownComponent } from 'ngx-countdown';
@Component({
  selector: 'app-session-dialog',
  templateUrl: './session-dialog.component.html',
  styleUrls: ['./session-dialog.component.scss']
})
export class SessionDialogComponent {
  isLoggingOut: boolean = false;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  constructor(
    public dialogRef: MatDialogRef<SessionDialogComponent>,
    private authService: AuthService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.countdown?.begin()
  }

  handleEvent(event) {
    if (event.action == 'done') {
      this.isLoggingOut = true
      this.authService.signOut().subscribe((data) => {
        this.dialogRef.close('lock');
      }, (error) => {
        this.dialogRef.close('lock');
      });
    }
  }

  logout() {
    this.isLoggingOut = true
    this.authService.signOut().subscribe((data) => {
      this.dialogRef.close('logout')
    }, (error) => {
      this.dialogRef.close('lock');
    });
  }
}
