import { HttpClient, HttpHeaders, HttpParams, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { UtilityService } from './utility.service';
import { of, Subject, takeUntil, map, takeWhile, tap, Observable, expand, delay, throwError, catchError } from 'rxjs';
import { environment } from 'environments/environment';
import { FuseConfigService } from '@fuse/services/config';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    baseUrl = environment.APIUrl;
    mainUrl = environment.APIMainUrl;
    config;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _http: HttpClient,
        private _fuseConfigService: FuseConfigService) {
        // Get app configuration
        this._fuseConfigService.config$.pipe(
            takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
                this.config = config
            });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }


    /**
     * Get api
     * @param path Api endpoint
     * @returns
     */
    get(path) {
        // add language
        let url = this.baseUrl + (this.config.language ?? 'en-in/')
        return this._http.get(url + path);
    }

    /**
     * Get api for SPARK domain
     * @param path Api endpoint
     * @returns
     */
    getExternal(path) {
        // add language
        let url = this.mainUrl + (this.config.language ?? 'en-in/')
        return this._http.get(url + path);
    }

    /**
     * Post api for downloading files from server
     * @param path Api endpoint
     * @param data
     * @returns
     */
    download(path, data?) {
        // add language
        let url = this.baseUrl + (this.config.language ?? 'en-in/')
        return this._http.post(url + path, data, {
            responseType: 'blob',
            observe: 'response'
        }).pipe(map((res: any) => {
            if (res.body) {
                if (res.headers.get('Content-Type') == "application/json") {
                    return null;
                }
                else {
                    let data = {
                        data: new Blob([res.body], { type: res.headers.get('Content-Type') }),
                        filename: res.headers.get('File-Name')
                    }
                    return data;
                }
            }
            return null
        }
        ))
    }

    /**
     * Post api for downloading files from server
     * @param path Api endpoint
     * @param data
     * @returns
     */
    downloadGet(path) {
        // add language
        let url = this.baseUrl + (this.config.language ?? 'en-in/')
        return this._http.get(url + path, {
            responseType: 'blob',
            observe: 'response'
        }).pipe(map((res: any) => {
            if (res.body) {
                if (res.headers.get('Content-Type') == "application/json") {
                    return null;
                }
                else {
                    let data = {
                        data: new Blob([res.body], { type: res.headers.get('Content-Type') }),
                        filename: res.headers.get('File-Name')
                    }
                    return data;
                }
            }
            return null
        }
        ))
    }

    /**
     * Post api
     * @param path Api endpoint
     * @param data
     * @returns
     */
    post(path, data) {
        // add language
        let url = this.baseUrl + (this.config.partnerLanguage ?? 'en-in/')
        return this._http.post(url + path, data);
    }

}
