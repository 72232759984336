<button mat-icon-button (click)="settingsDrawer.toggle()" [matTooltip]="'Settings'"
    [class.lg:right-0]="config.layout === 'centered' || config.layout === 'material'">
    <mat-icon class="icon-size-6" [svgIcon]="'heroicons_solid:cog'"></mat-icon>
</button>
<fuse-drawer class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999" fixed [mode]="'over'" [name]="'settingsDrawer'"
    [position]="'right'" #settingsDrawer>
    <div class="flex flex-col w-full overflow-auto bg-card" *transloco="let t">
        <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
            <mat-icon class="icon-size-7 text-current" [svgIcon]="'heroicons_solid:cog'"></mat-icon>
            <div class="ml-3 text-2xl font-semibold tracking-tight">Settings</div>
            <button class="ml-auto" mat-icon-button (click)="settingsDrawer.close()">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>

        <div class="flex flex-col p-6">

            <!-- Theme
            <div class="text-md font-semibold text-secondary">{{t('settings.title.theme')}}</div>
            <div class="grid grid-cols-2 sm:grid-cols-3 gap-3 mt-6">
                <ng-container *ngFor="let theme of config.themes">
                    <div class="flex items-center justify-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                        [class.ring-2]="config.theme === theme.id" [ngClass]="theme.id" (click)="setTheme(theme.id)">
                        <div class="flex-0 w-3 h-3 rounded-full bg-primary"></div>
                        <div class="ml-2.5 font-medium leading-5 truncate"
                            [class.text-secondary]="config.theme !== theme.id">
                            {{theme.name}}
                        </div>
                    </div>
                </ng-container>
            </div>

            <hr class="my-8">-->

            <!-- Scheme -->
            <div class="text-md font-semibold text-secondary">{{t('settings.title.scheme')}}</div>
            <div class="grid grid-cols-3 gap-3 justify-items-start mt-6">
                <!-- Auto -->
                <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                    [class.ring-2]="config.scheme === 'auto'"
                    matTooltip="Automatically sets the scheme based on user's operating system's color scheme preference using 'prefer-color-scheme' media query."
                    (click)="setScheme('auto')">
                    <div class="flex items-center rounded-full overflow-hidden">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:lightning-bolt'"></mat-icon>
                    </div>
                    <div class="flex items-center ml-2 font-medium leading-5"
                        [class.text-secondary]="config.scheme !== 'auto'">
                        {{t('settings.title.auto')}}
                    </div>
                </div>
                <!-- Dark -->
                <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                    [class.ring-2]="config.scheme === 'dark'" (click)="setScheme('dark')">
                    <div class="flex items-center rounded-full overflow-hidden">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:moon'"></mat-icon>
                    </div>
                    <div class="flex items-center ml-2 font-medium leading-5"
                        [class.text-secondary]="config.scheme !== 'dark'">
                        {{t('settings.title.dark')}}
                    </div>
                </div>
                <!-- Light -->
                <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                    [class.ring-2]="config.scheme === 'light'" (click)="setScheme('light')">
                    <div class="flex items-center rounded-full overflow-hidden">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:sun'"></mat-icon>
                    </div>
                    <div class="flex items-center ml-2 font-medium leading-5"
                        [class.text-secondary]="config.scheme !== 'light'">
                        {{t('settings.title.light')}}
                    </div>
                </div>
            </div>

            <!-- Session -->
            <ng-container
                *ngIf="(_userService.user$ | async)?.user_login?.user_role_name?.user_role_slug == 'developer'">
                <hr class="my-8">
                <div class="text-md font-semibold text-secondary uppercase">{{t('title.session')}}</div>
                <div class="grid grid-cols-2 gap-3 justify-items-start mt-6">
                    <!-- 15 -->
                    <div class="flex items-center w-full py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                        [class.ring-2]="config.idleTime === 900" (click)="setSession(900)">
                        <div class="flex items-center rounded-full overflow-hidden">
                            <mat-icon class="icon-size-5">timer</mat-icon>
                        </div>
                        <div class="flex items-center ml-2 font-medium leading-5"
                            [class.text-secondary]="(config.idleTime === 900)">
                            {{t('settings.title.15_min')}}
                        </div>
                    </div>
                    <!-- 30 -->
                    <div class="flex items-center w-full py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                        [class.ring-2]="config.idleTime === 1800" (click)="setSession(1800)">
                        <div class="flex items-center rounded-full overflow-hidden">
                            <mat-icon class="icon-size-5">timer</mat-icon>
                        </div>
                        <div class="flex items-center ml-2 font-medium leading-5"
                            [class.text-secondary]="config.idleTime === 1800">
                            {{t('settings.title.30_min')}}
                        </div>
                    </div>
                    <!-- 45 -->
                    <div class="flex items-center w-full py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                        [class.ring-2]="config.idleTime === 2700" (click)="setSession(2700)">
                        <div class="flex items-center rounded-full overflow-hidden">
                            <mat-icon class="icon-size-5">timer</mat-icon>
                        </div>
                        <div class="flex items-center ml-2 font-medium leading-5"
                            [class.text-secondary]="config.idleTime === 2700">
                            {{t('settings.title.45_min')}}
                        </div>
                    </div>
                    <!-- 60 -->
                    <div class="flex items-center w-full py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                        [class.ring-2]="config.idleTime === 3600" (click)="setSession(3600)">
                        <div class="flex items-center rounded-full overflow-hidden">
                            <mat-icon class="icon-size-5">timer</mat-icon>
                        </div>
                        <div class="flex items-center ml-2 font-medium leading-5"
                            [class.text-secondary]="config.idleTime === 3600">
                            {{t('settings.title.60_min')}}
                        </div>
                    </div>
                </div>
            </ng-container>


        </div>

    </div>

</fuse-drawer>