import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'lodash';
import { Subscription, ReplaySubject, Observable, timer, map, take, tap, merge, async, from, of, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../common-services/http.service';
import { LocalStorageService } from '../common-services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    private timerSubscription: any;
    private geoWatch: any;
    private _location: ReplaySubject<any> = new ReplaySubject<any>(1);

    constructor(
        private _httpService: HttpService,
        private _localStorageService: LocalStorageService,
        private _router: Router
    ) { }

    get location$(): Observable<any> {
        return this._location.asObservable();
    }

    getPosition() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((position) => {
                this._location.next({ latitude: position.coords.latitude, longitude: position.coords.longitude })
                resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude })
            }, (error) => {
                reject(null)
            });
        })

    }

    start() {
        let data;

        this.getPosition().then((position) => {
            data = position
        })

        this.geoWatch = navigator.geolocation.watchPosition(position => {
            data = position
        })

        if (!this.timerSubscription)
            this.timerSubscription = setInterval(() => {
                if (data) {
                    this._location.next({ latitude: data.coords.latitude, longitude: data.coords.longitude, time: (new Date().getTime() / 1000) })

                    let locationData: any = {
                        user_login_activity_key: this._localStorageService.accessToken.userActivityKey,
                        latitude: data.coords.latitude,
                        longitude: data.coords.longitude
                    };

                    if (navigator.onLine) {
                        if (this._localStorageService.getStorage('locationData', true)) {
                            locationData = {
                                tracking_logs_list: [...this._localStorageService.getStorage('locationData', true)]
                            }
                        }
                        this._httpService.post('location/addLocation', {
                            user_login_activity_key: this._localStorageService.accessToken.userActivityKey,
                            ...locationData
                        }).subscribe((data: any) => {
                            if (data.status_code != 200) {
                                this.stop()
                            }
                            else
                                this._localStorageService.deleteStorage('locationData', true)
                        }, (error) => {
                            this.stop()
                        })
                    }
                    else {
                        let previousData: any = this._localStorageService.getStorage('locationData', true) ?? []
                        previousData.push({
                            latitude: data.coords.latitude,
                            longitude: data.coords.longitude,
                            created_at: formatDate(new Date(), 'yyyy-MM-dd hh:mm a', 'en-in')
                        })
                        this._localStorageService.setStorage('locationData', previousData, true)
                    }
                    //console.log({ data: data.coords, time: (new Date().getTime() / 1000) });
                }
            }, 300000)


        // this.timerSubscription = setInterval(async () => {
        //     let data: any;


        //     await this.getPosition().then((dt) => {
        //         data = dt
        //     })
        //     console.log({ data, ...{ time: (new Date().getTime() / 1000) } });
        // }, 5000)
        // if (!this.timerSubscription) {
        //     this.timerSubscription = setInterval(() => {
        //         navigator.geolocation.getCurrentPosition((position) => {
        //             let data = {
        //                 user_login_activity_key: this._localStorageService.accessToken.userActivityKey,
        //                 latitude: position.coords.latitude,
        //                 longitude: position.coords.longitude
        //             };
        //             console.log((new Date().getTime() / 1000));

        //             // this._httpService.post('location/addLocation', data).subscribe((data: any) => {
        //             //     if (data.status_code != 200) {
        //             //         this.timerSubscription.unsubscribe()
        //             //     }
        //             // }, (error) => {
        //             //     this.timerSubscription.unsubscribe()
        //             // })

        //         }, (error) => {
        //             this._router.navigate(['sign-in'], { queryParams: { force: true } });
        //             this.timerSubscription?.unsubscribe()
        //         });
        //     }, 5000)

        // }
    }

    stop() {
        navigator.geolocation.clearWatch(this.geoWatch)
        clearInterval(this.timerSubscription)
        this.timerSubscription = null
    }

    ngOnDestroy(): void {
        navigator.geolocation.clearWatch(this.geoWatch)
        //this._location.unsubscribe()
        clearInterval(this.timerSubscription)
        this.timerSubscription = null
    }
}

