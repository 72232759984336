<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-8" src="{{(_fuseConfigService.config$ | async).logo}}">
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!-- <notifications></notifications> -->
                <user [showAvatar]="false"></user>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img class="w-full h-full rounded-full" *ngIf="user?.profile_photo_path"
                    [src]="user?.profile_photo_path" alt="User avatar">
                <mat-icon class="icon-size-24" *ngIf="!user?.profile_photo_path"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user?.user_name_prefix?user.user_name_prefix.name_prefix+'. ':''}} {{user?.first_name}}
                    {{user?.last_name}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user?.user_login?.user_role_name?.user_role_name}}
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>

    </ng-container>

    <ng-container fuseVerticalNavigationFooter *transloco="let t">
        <p *ngIf="user?.user_login?.user_role_name?.user_role_slug=='field_officer'" class="ml-4 mt-2">
            {{t('feedback.title.total_recoveries')}}: {{recoveryTotal?"&#8377;"+
            recoveryTotal.toLocaleString('en-IN'):'NA'}}
        </p>
        <div class="flex flex-0 items-center justify-center h-20 pr-6 pl-2 mt-2 mb-2 opacity-20">
            <img class="max-w-40" src="{{(_fuseConfigService.config$ | async).logoWithText}}">
        </div>
        <div class="nav-header-footer text-left text-gray-400">
            <h4 class="">V {{appVersion}}</h4>
            <h4 class="mt-0 text-gray-400">{{t('design_developed_by')}} <a [href]="orgURL"
                    target="_blank">{{t('spark_technologies')}}</a>
            </h4>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0" *transloco="let t">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages> -->

            <!-- ----------------------------------------------------------------------------------------------------- -->
            <!-- Settings drawer - Remove this to remove the drawer and its button -->
            <!-- ----------------------------------------------------------------------------------------------------- -->
            <settings></settings>

            <ng-container *ngIf="
            (_userService.user$ | async).user_login?.user_role_name?.user_role_slug=='owner'
            || (_userService.user$ | async).user_login?.user_role_name?.user_role_slug=='developer'">
                <button mat-icon-button #dateMenu="matMenuTrigger" [matTooltip]="t('allocation.title.extend_dates')"
                    [matMenuTriggerFor]="appMenu">
                    <mat-icon>calendar_month</mat-icon>
                </button>
                <mat-menu #appMenu="matMenu" class="drop-calendar">
                    <div (click)="$event.stopPropagation()">
                        <mat-calendar [minDate]="startOfMonth" [maxDate]="endOfMonth" #calendar
                            (selectedChange)="select($event,calendar)" [dateClass]="isSelected">
                        </mat-calendar>
                        <div class="flex gap-2 justify-end pr-3 mb-2">
                            <button mat-button color="primary"
                                (click)="dateMenu.closeMenu()">{{t('action.cancel')}}</button>
                            <button mat-flat-button [disabled]="isLoading" (click)="addDates()" color="primary">
                                <span *ngIf="!isLoading">{{t('action.apply')}}</span>
                                <div *ngIf="isLoading">
                                    <mat-spinner [diameter]="20"></mat-spinner>
                                </div>
                            </button>
                        </div>
                    </div>
                </mat-menu>
            </ng-container>

            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->

</div>