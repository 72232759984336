import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { environment } from 'environments/environment';
import { FuseConfigService } from '@fuse/services/config';
import { Subject, takeUntil } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    deleteConfigForm: FormGroup

    config: any;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private cookieService: CookieService,
        private _router: Router,
        private _fuseConfigService: FuseConfigService,
        private _translocoService: TranslocoService,
        private _toaster: ToastrService) {
        // Get app configuration
        this._fuseConfigService.config$.pipe(
            takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
                this.config = config
            });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1))
    minDate = new Date(2000, 0, 1)

    /**
     * Scroll to specific form field
     * @param name Form control name
     */
    scrollByFormName(name) {
        let el = document.querySelector('[formControlName="' + name + '"]');
        el?.scrollIntoView({ behavior: 'smooth' });
    }

    /**
     * Scroll to element
     * @param name id
     */
    scrollById(name) {
        let el = document.getElementById(name);
        el?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }

    /**
     * Open pdf from blob data
     * @param data
     */
    openPdf(data) {
        var file = new Blob([data], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Encrypt and decrypt
    // -----------------------------------------------------------------------------------------------------

    /**
     * Encrypt with SHA256 (can't decrypt)
     * @param data
     * @returns
     */
    encrypt(data) {
        let key = environment.isDebug ? data : CryptoJS.SHA256(data).toString();
        return key;
    }

    /**
     * Encrypt with password (can be decrypt with password)
     * @param data
     * @returns
     */
    encryptAES(data) {
        let key = environment.isDebug ? data : CryptoJS.AES.encrypt(data, environment.passphrase).toString()
        return key;
    }

    /**
     * Decrypt with password
     * @param data
     * @returns
     */
    decryptAES(data) {
        try {
            return environment.isDebug ? data : CryptoJS.AES.decrypt(data, environment.passphrase).toString(CryptoJS.enc.Utf8)
        } catch (error) {
            //this._router.navigateByUrl('error/700?ref=' + encodeURIComponent(window.location.href))
            return ''
        }
    }

    /**
     * Clear login
     */
    clearSession() {
        localStorage.removeItem(this.encrypt('accessToken'))
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Cookie operations
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set cookie
     * @param name cookie name
     * @param data
     */
    setCookie(name, data) {
        let key = this.encrypt(name);
        var now = new Date();
        let devExp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
        let prodExp = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 15);
        this.cookieService.set(
            key,
            this.encryptAES(data),
            { domain: environment.domain, expires: environment.production ? prodExp : devExp, path: '/' })
    }

    /**
     * Clear cookie by name
     * @param name
     */
    clearCookie(name) {
        let key = this.encrypt(name);
        this.cookieService.delete(key, '/')
    }

    /**
     * Get cookie data
     * @param name
     * @returns
     */
    getCookie(name) {
        let key = this.encrypt(name);
        return this.cookieService.get(key)
    }

    /**
     * Copy selected text
     * @param val
     */
    copyText(val: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Form utilities
    // -----------------------------------------------------------------------------------------------------

    /**
     * Convert json into formData (use for form with media upload)
     * @param data json data
     * @param form Empty form object
     * @param name
     * @returns
     */
    toFormData(data, form, name?) {
        if (typeof data === 'object' && data != undefined) {
            for (let key in data) {
                if (data[key]) {
                    if (typeof data[key] === 'object' && data[key] != null && !(data[key] instanceof File)) {
                        if (name)
                            this.toFormData(data[key], form, name + '[' + key + ']')
                        else {
                            this.toFormData(data[key], form, key)
                        }
                    }
                    else {
                        if (!name)
                            form.append(key, data[key]);
                        else
                            form.append(name + '[' + key + ']', data[key]);
                    }
                }

            }
        }
        else {
            form.append(name, data);
        }

        return form;
    }

    /**
     * Extract file name from url
     * @param path url
     * @returns
     */
    getFileName(path) {
        return path.replace(/^.*[\\\/]/, '');
    }

    /**
     * Build breadcrumb
     * @param route
     * @param url
     * @param breadcrumbs
     * @returns
     */
    buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []): any {
        const children: ActivatedRoute[] = route.children;

        if (children.length === 0) {
            return breadcrumbs;
        }

        for (const child of children) {
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
            if (routeURL !== '') {
                url += `/${routeURL}`;
            }
            const label = child.snapshot.data['breadcrumb'];
            if (label) {
                breadcrumbs.push({ label, url });
            }

            return this.buildBreadCrumb(child, url, breadcrumbs);
        }
    }

    /**
     * Password and confirm password check
     * @param controlName
     * @param matchingControlName
     * @returns
     */
    MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        }
    }

    /**
     * Reactive form custom limit validator
     * @param controlName
     * @param limit
     * @returns
     */
    checkMaxValueLimit(controlName: string, limit) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            // set error on matchingControl if validation fails
            if (control.errors && !control.errors['limit']) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
            if (control.value > limit) {
                control.setErrors({ limit: true });
            } else {
                control.setErrors(null);
            }
        }
    }

    checkMinValueLimit(controlName: string, limit) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            // set error on matchingControl if validation fails
            if (control.errors && !control.errors['limit']) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
            if (control.value < limit) {
                control.setErrors({ limit: true });
            } else {
                control.setErrors(null);
            }
        }
    }

    /**
     * Translate text
     */
    translate(path): String {
        return this._translocoService.translate(path)
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Popup and toaster
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show success toaster
     * @param message
     * @returns
     */
    showSuccess(message: String) {
        return this._toaster.success(message.toString(), '', { progressBar: true });
    }

    /**
     * Show success swal
     * @param message
     */
    showSuccessSwal(message) {
        Swal.fire(
            {
                title: message,
                heightAuto: false,
                icon: 'success'
            }
        )
    }

    /**
     * Welcome toaster after login
     * @param message
     * @returns
     */
    welcomeUser(message: String) {
        return this._toaster.success("Welcome to " + this.config.appName, 'Hey, ' + message, { progressBar: true });
    }

    /**
     * Show failure toaster
     * @param message
     * @returns
     */
    showFailure(message: String) {
        return this._toaster.error(message.toString());
    }

    /**
     * Show failure swal
     * @param message
     */
    showFailureSwal(message) {
        Swal.fire(
            {
                title: message,
                heightAuto: false,
                icon: 'error'
            }
        )
    }

    /**
     * Show warning toaster
     * @param message
     * @returns
     */
    showWarning(message: String) {
        return this._toaster.warning(message.toString());
    }

    /**
     * Show bottom toaster
     * @param message
     */
    showBottomSuccess(message: String) {
        this._toaster.show(message.toString(), '', {
            positionClass: 'toast-bottom-center',
            toastClass: 'ngx-toastr process-toaster',
        });
    }

    /**
     * Show processat bottom
     * @param message
     * @param id
     * @returns
     */
    showProcess(message: String, id) {
        return this._toaster.show(message.toString(), '',
            {
                positionClass: 'toast-bottom-center',
                toastClass: 'ngx-toastr process-toaster',
                timeOut: 0,
                tapToDismiss: false,
                progressBar: true
            }).toastId = id;
    }

    /**
     * Remove process
     * @param id
     */
    removeProcess(id) {
        this._toaster.remove(id);
    }

    validationList: any[] = [
        { 'name': "At least one digit", "status": false },
        { 'name': "min 8 character", "status": false },
        { 'name': "At least one lower case", "status": false },
        { 'name': "At least one upper case", "status": false },
        { 'name': "At least one special character", "status": false },
    ];

    /**
     * Warning swal
     * @param message
     * @returns
     */
    warningDialog(message) {
        let parser = new DOMParser();
        let tg = parser.parseFromString(message, 'text/html').body;
        return Swal.fire({
            text: tg.textContent?.toString(),
            showCancelButton: true,
            icon: 'warning',
            reverseButtons: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'red',
            heightAuto: false,
            customClass: {
                confirmButton: 'delete-button',
            },
            cancelButtonColor: '#dadada',
        });
    }

    /**
     * Delete confirmation swal
     * @param data
     * @returns
     */
    deleteDialogue(data) {
        let parser = new DOMParser();
        let tg = parser.parseFromString('Are you sure to remove <b>' + data + '</b>?', 'text/html').body;
        return Swal.fire({
            text: tg.textContent?.toString(),
            showCancelButton: true,
            icon: 'warning',
            reverseButtons: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
            confirmButtonColor: 'red',
            heightAuto: false,
            customClass: {
                confirmButton: 'delete-button',
            },
            cancelButtonColor: '#dadada',
        });
    }

    /**
     * Restore swal
     * @param data
     * @returns
     */
    restoreDialogue(data) {
        let parser = new DOMParser();
        let tg = parser.parseFromString('Are you sure to restore <b>' + data + '</b>?', 'text/html').body;
        console.log(tg)
        return Swal.fire({
            text: tg.textContent?.toString(),
            showCancelButton: true,
            confirmButtonText: 'Yes, restore it!',
            cancelButtonText: 'No, keep it',
            confirmButtonColor: '#01a317',
            heightAuto: false,
            cancelButtonColor: '#dadada',
        });
    }

    /**
     * Generate password
     * @param length
     * @returns
     */
    generatePassword(length?: number) {
        const passwordLength = length || 12;
        const lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        const upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        const symbols = ['!', '?', '@', ')', '(', '%', '$', '#'];

        const getRandom = array => array[Math.floor(Math.random() * array.length)];
        let finalCharacters = '';
        finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
        finalCharacters = finalCharacters.concat(getRandom(numbers));
        finalCharacters = finalCharacters.concat(getRandom(symbols));
        for (let i = 0; i < passwordLength - 3; i++) {
            finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
        }
        return finalCharacters.split('').sort(() => 0.5 - Math.random()).join('');
    }

    /**
     * Convert to normal text
     * @param str
     * @returns
     */
    toProperCase(str) {
        let newStr = str.replace('_', ' ')
        return newStr.charAt(0).toUpperCase() + newStr.slice(1);
    };

    /**
     * Convert to snake case
     * @param str
     * @returns
     */
    toSnakeCase(str) {
        let newStr = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(s => s.toLowerCase())
            .join('_');
        return newStr;
    };

    /**
     * Generate time intervals
     * @param startHourInMinute
     * @param endHourInMinute
     * @param interval
     * @returns
     */
    generateHoursInterval = (
        startHourInMinute,
        endHourInMinute,
        interval,
    ) => {
        let times: any[] = [];

        for (let i = 0; startHourInMinute < 24 * 60; i++) {
            if (startHourInMinute > endHourInMinute + interval) break;

            var hh = Math.floor(startHourInMinute / 60); // getting hours of day in 0-24 format

            var mm = startHourInMinute % 60; // getting minutes of the hour in 0-55 format

            times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2);

            startHourInMinute = startHourInMinute + interval;
        }

        return times;
    };

    /**
     * Load external script dynamically
     * @param url
     */
    public loadScript(url) {
        let body = <HTMLDivElement>document.body;
        let script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = true;
        script.defer = true;
        body.appendChild(script);
    }

    /**
     * Create array
     * @param i
     * @returns
     */
    counter(i: number) {
        return new Array(i);
    }

}
