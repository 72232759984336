import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, firstValueFrom, from, Observable, of, Subject, switchMap, takeUntil, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { Idle } from '@ng-idle/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpService } from '../common-services/http.service';
import { FcmMessagingService } from '../messaging/fcm-messaging.service';
import { Messaging } from '@angular/fire/messaging';
import { lowerCase } from 'lodash';
import { UtilityService } from '../common-services/utility.service';
import { LocationService } from '../location/location.service';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    deviceInfo: any;
    locationInfo: any;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _httpService: HttpService,
        private _userService: UserService,
        private _locationService: LocationService,
        private idle: Idle,
        private _utilityService: UtilityService,
        private deviceService: DeviceDetectorService,
    ) {
        this.deviceInfo = this.deviceService.getDeviceInfo();

        this._locationService.location$.subscribe((position: any) => {
            this.locationInfo = position
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(data: any) {
        localStorage.setItem(this._utilityService.encrypt('accessToken'), this._utilityService.encryptAES(JSON.stringify(data)));
    }

    get accessToken(): any {
        try {
            return JSON.parse(this._utilityService.decryptAES(
                localStorage.getItem(
                    this._utilityService.encrypt('accessToken')) ? localStorage.getItem(
                        this._utilityService.encrypt('accessToken')) : ''));
        } catch (error) {
            return ''
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpService.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpService.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials): Observable<any> {
        credentials.browser_name = this.deviceInfo.browser
        credentials.browser_version = this.deviceInfo.browser_version
        credentials.os = this.deviceInfo.os
        credentials.os_version = this.deviceInfo.os_version
        const isMobile = this.deviceService.isMobile();
        const isDesktopDevice = this.deviceService.isDesktop();
        credentials.device_type = isDesktopDevice ? 0 : (isMobile ? 1 : 0)

        return this._httpService.post('login', credentials).pipe(
            switchMap((response: any) => {

                if (response.success == 1) {
                    // Store the access token in the local storage
                    this.accessToken = {
                        token: response.data.api_token,
                        userActivityKey: response.data.user_login_activity_key
                    };

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    if (response.data?.user_role_slug) {
                        if (response.data?.user_role_slug == 'developer')
                            // Start Idle time
                            this.idle.watch()
                        else
                            this._locationService.start()
                    }

                    this._utilityService.welcomeUser(response.data.first_name + " " + response.data.last_name)

                    // Store the user on the user service
                    this._userService.user = response.data;
                }
                else {
                    throw (response)
                }

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        let data: any = {
            api_token: this.accessToken.token,
            device_type: 0
        }

        if (this.locationInfo) {
            data.latitude = this.locationInfo.latitude
            data.longitude = this.locationInfo.longitude
        }

        return this._httpService.post('logout', data)
            .pipe(
                switchMap((response: any) => {
                    this._utilityService.clearSession()
                    this._authenticated = false
                    this.idle.stop()
                    this._locationService.stop()
                    return of(true);
                }));


    }
    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpService.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpService.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {

        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken.token) {
            return of(false);
        }

        this._userService.user$.pipe((takeUntil(this._unsubscribeAll))).subscribe((user: any) => {
            if (user.user_login?.user_role_name?.user_role_slug) {
                if (user.user_login?.user_role_name?.user_role_slug == 'developer')
                    // Start Idle time
                    this.idle.watch()
                else
                    this._locationService.start()
            }
        })

        // If the access token exists and it didn't expire, sign in using it
        return of(true);
    }

    /**
 * On destroy
 */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
