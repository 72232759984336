import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { UserService } from 'app/core/user/user.service';
import { PushNotificationsService } from 'ng-push-ivy';
import { Subscription, Subject, takeUntil } from 'rxjs';
import { FuseConfigService } from '../config';
import { SessionDialogComponent } from './session-dialog/session-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class IdleSessionService {
    navigation: any;
    isLoading = false;
    idleState = 'Not started.';
    timedOut = false;
    lastPing = null;
    sessionForm: FormGroup
    remainTime = 900;
    countDown: Subscription;
    dialogRef;
    user;
    redirectURL = ''
    logo: any = ''
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(
        private router: Router,
        private idle: Idle,
        private _pushNotifications: PushNotificationsService,
        private _configService: FuseConfigService,
        private _userService: UserService,
        public dialog: MatDialog) {

        this.router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((event: any) => {
            switch (true) {
                case event instanceof NavigationEnd: {
                    this.redirectURL = event.url
                    this.dialog.closeAll()
                    break;
                }
                default: {
                    break;
                }
            }
        })
        this._init()
    }

    private _init(): void {
        this._configService.config$.pipe(takeUntil(this._unsubscribeAll)).subscribe((options) => {
            this.logo = options.logo
            if (options?.idleTime && this.remainTime != options?.idleTime) {
                this.remainTime = options.idleTime
                //this.remainTime = 10
                this.idle.setIdle(this.remainTime);
            }
        });

        this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
            this.user = data
        })

        this._pushNotifications.requestPermission();
        this.idle.setIdle(this.remainTime);
        this.idle.setTimeout(20);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.';
        })

        this.idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.idle.stop()
            this.dialogRef.close('lock')
        });

        this.idle.onIdleStart.subscribe(() => {
            this.notify();
            this.idleState = 'You\'ve gone idle!'
            this.idle.stop()
            this.dialogRef = this.dialog.open(SessionDialogComponent, {
                width: '400px',
                data: '',
                disableClose: true
            });
            this.dialogRef?.afterClosed().subscribe((result) => {
                this.idle.stop()
                if (result == 'stay') {
                    this.idle.watch()
                }
                else if (result == 'lock') {
                    this.router.navigate(['unlock-session', this.user?.user_login?.username], { queryParams: { force: true, redirectURL: this.redirectURL } });
                    //this.router.navigateByUrl('/unlock-session/' + this.user?.user_login?.username + '?force=true&redirectURL=' + routerStateSnapshot.url)
                }
                else if (result == 'logout') {
                    this.router.navigateByUrl('/sign-in?force=true')
                }
            });
        });
    }

    notify() { //our function to be called on click
        let options = { //set options
            body: "Locking session in 20 seconds!",
            sticky: true,
            vibrate: [2],
            icon: this.logo
        }
        this._pushNotifications.create('You found idle!', options).subscribe( //creates a notification
            res => {
                if (res.event.type === 'click') {
                    window.focus();
                    res.notification.close();
                }
            },
            err => { }
        );
    }

    /**
    * On destroy
    */
    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

}
