import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { UtilityService } from './utility.service';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor(
        private _utilityService: UtilityService,
    ) { }

    /**
     * Setter & getter for access token
     */
    set accessToken(data: any) {
        localStorage.setItem(this._utilityService.encrypt('accessToken'), this._utilityService.encryptAES(JSON.stringify(data)));
    }

    get accessToken(): any {
        try {
            return JSON.parse(this._utilityService.decryptAES(
                localStorage.getItem(
                    this._utilityService.encrypt('accessToken')) ? localStorage.getItem(
                        this._utilityService.encrypt('accessToken')) : ''));
        } catch (error) {
            return ''
        }
    }

    setStorage(name, data, isEncrypt = false) {
        if (isEncrypt && !environment.isDebug)
            localStorage.setItem(this._utilityService.encrypt(name), this._utilityService.encryptAES(JSON.stringify(data)));
        else
            localStorage.setItem(name, JSON.stringify(data));

    }

    getStorage(name, isEncrypt = false) {
        if (isEncrypt && !environment.isDebug) {
            return localStorage.getItem(
                this._utilityService.encrypt(name)) ?
                JSON.parse(this._utilityService.decryptAES(
                    localStorage.getItem(
                        this._utilityService.encrypt(name)))) : null;
        }

        else
            return JSON.parse(localStorage.getItem(name));

    }

    deleteStorage(name, isEncrypt = false) {
        if (isEncrypt && !environment.isDebug)
            localStorage.removeItem(this._utilityService.encrypt(name));
        else
            localStorage.removeItem(name);
    }
}
