import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/common-services/http.service';
import { UtilityService } from 'app/core/common-services/utility.service';
import * as configData from 'assets/config.json'

@Injectable({
    providedIn: 'root'
})
export class AllocationsService {

    config = configData;

    constructor(private _httpService: HttpService,
        private _utility: UtilityService) { }

    /**
     * Get companies tabs
     */
    getProductTabs() {
        return new Promise((resolve, reject) => {
            this._httpService.get('company/getTabs').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get loans
     */
    getLoans(page, companyKey, bucket, keyword?, limit?) {
        let params = new HttpParams();
        params = params.set('page', page);
        params = params.set('company_key', companyKey);
        if (limit)
            params = params.set('limit', limit);
        if (keyword)
            params = params.set('search_key', keyword);
        if (bucket)
            params = params.set('bucket_key', bucket);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/getAll?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get Fo list
     */
    getFieldOfficerList() {
        return new Promise((resolve, reject) => {
            this._httpService.get('user/getAll?is_list=1').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get allocation loans
     */
    getAllocationLoans(page, companyKey, lastFo, keyword?, limit?) {
        let params = new HttpParams();
        params = params.set('page', page);
        params = params.set('company_key', companyKey);
        if (lastFo)
            params = params.set('last_month_allocation_key', lastFo);
        if (limit)
            params = params.set('limit', limit);
        if (keyword)
            params = params.set('search_key', keyword);
        return new Promise((resolve, reject) => {
            this._httpService.get('allocation/loan/getAll?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get payment status
     */
    getPaymentStatus(page, companyKey, paymentStatus?, from?, to?, keyword?, limit?) {
        let params = new HttpParams();
        params = params.set('page', page);
        params = params.set('company_key', companyKey);
        if (from)
            params = params.set('from', from);
        if (to)
            params = params.set('to', to);
        if (paymentStatus)
            params = params.set('payment_status_key', paymentStatus);
        if (limit)
            params = params.set('limit', limit);
        if (keyword)
            params = params.set('search_key', keyword);
        return new Promise((resolve, reject) => {
            this._httpService.get('loan/payment/getAll?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get company allocation tabs
     */
    getAllocationCompaniesTabs() {
        return new Promise((resolve, reject) => {
            this._httpService.get('allocation/company/getTabs').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Allocation loans
     * @param data
     * @returns
     */
    addAllocations(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('allocation/addAllocation', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data.data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Get allocated loans
     */
    getAllocatedLoans(page, year, fo, pos, bucketKey, companyKey, paymentStatusKey, keyword?, limit?) {
        let params = new HttpParams();
        params = params.set('page', page);
        if (limit)
            params = params.set('limit', limit);
        if (year)
            params = params.set('from_year', year);
        if (keyword)
            params = params.set('search_key', keyword);
        if (fo)
            params = params.set('user_details_key', fo);
        if (pos != null)
            params = params.set('pos', pos);
        if (bucketKey)
            params = params.set('bucket_drc_key', bucketKey);
        if (companyKey)
            params = params.set('company_drc_key', companyKey);
        if (paymentStatusKey)
            params = params.set('payment_status_drc_key', paymentStatusKey);
        return new Promise((resolve, reject) => {
            this._httpService.get('allocation/getAll?' + params).subscribe((data: any) => {
                resolve(data.data);
            },
                (error) => {
                    reject(error)
                })
        })
    }


    /**
   * Delete  Employee
   * @param data
   * @returns
   */
    removeLoanAllocationDetails(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('allocation/deleteAllocation', { 'allocation_key': data }).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
     * Allocation loans
     * @param data
     * @returns
     */
    reAllocationsLoans(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('allocation/reAllocate', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data.data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }


    /**
     * Get dates
     */
    getDates() {
        return new Promise((resolve, reject) => {
            this._httpService.get('allocation/extended/getDates').subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data.data);
                }
                else {
                    reject(data)
                }
            },
                (error) => {
                    reject(error)
                })
        })
    }

    /**
    * Add dates
    */
    addDates(data) {
        return new Promise((resolve, reject) => {
            this._httpService.post('allocation/extended/addDates', data).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data.data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }


    /**
     * Verify payment status
     * @param data
     * @returns
     */
    verifyPaymentStatus(data) {
        let formData = new FormData()
        this._utility.toFormData(data, formData);
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/import/payment/status/verifyPayments', formData).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Download sample
     * @param data
     * @returns
     */
    downloadPaymentStatusSample() {
        return new Promise((resolve, reject) => {
            this._httpService.downloadGet('loan/import/payment/status/getFormat').subscribe((data: any) => {
                resolve(data)
            }, (error) => {
                reject(error)
            })
        })
    }

    /**
     * Import payment status
     * @param data
     * @returns
     */
    importPaymentStatus(data) {
        let formData = new FormData()
        this._utility.toFormData(data, formData);
        return new Promise((resolve, reject) => {
            this._httpService.post('loan/import/payment/status/importAll', formData).subscribe((data: any) => {
                if (data.status_code == 200) {
                    resolve(data)
                }
                else {
                    reject(data)
                }
            }, (error) => {
                reject(error)
            })
        })
    }
}
