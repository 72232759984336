import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { appConfig } from './core/config/app.config';
import { LocationService } from './core/location/location.service';
import { FcmMessagingService } from './core/messaging/fcm-messaging.service';
import { UtilityService } from './core/common-services/utility.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    /**
     * Constructor
     */

    constructor(
        //private _messagingService: FcmMessagingService,
        private _locationService: LocationService,
        private router: Router,
        private utility: UtilityService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title
    ) {

    }

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        ).subscribe(() => {
            const rt = this.getChild(this.activatedRoute);
            rt.data.subscribe(data => {
                if (data.title)
                    this.titleService.setTitle(data.title + " | " + appConfig.appName)
                else
                    this.titleService.setTitle(appConfig.appName.toString())
            });
        });
    }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }

    }
}


