<ng-container *transloco="let t">
    <h1 mat-dialog-title>
        <mat-icon class="text-red-500" style="vertical-align:middle">timelapse</mat-icon>
        <span class="align-middle ml-2" style="vertical-align:middle">{{t('auth.title.you_found_idle')}}</span>
    </h1>
    <div mat-dialog-content>
        <ng-container *ngIf="!isLoggingOut">
            <p style="font-size: 15px;">{{t('auth.title.system_will_be_locked_in')}} <b>
                    <countdown #cd [config]="{leftTime: 20,format: 'ss'}" (event)="handleEvent($event)"></countdown>
                </b></p>
        </ng-container>
        <ng-container *ngIf="isLoggingOut">
            <p style="font-size: 15px;">{{t('auth.title.logging_out_system')}}</p>
        </ng-container>
    </div>
    <div mat-dialog-actions class="justify-end">
        <button mat-button class="add-round-button" (click)="logout()">{{t('auth.title.logout')}}</button>
        <button mat-button class="success-button add-round-button" color="primary"
            (click)="this.dialogRef.close('stay')" cdkFocusInitial>{{t('action.stay')}}</button>
    </div>
</ng-container>