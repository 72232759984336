import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'checkNullObject'
})
export class CheckNullObjectPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        return Object.values(value).every(x => x === null || x === '');;
    }

}
