import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionDialogComponent } from './session-dialog/session-dialog.component';
import { IdleSessionService } from './idle-session.service';
import { CountdownModule } from 'ngx-countdown';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { PushNotificationsModule } from 'ng-push-ivy';

@NgModule({
  declarations: [
    SessionDialogComponent
  ],
  imports: [
    CommonModule,
    TranslocoCoreModule,
    CountdownModule,
    MatIconModule,
    PushNotificationsModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class IdleSessionModule {
  constructor(private _idleSessionService: IdleSessionService) {
  }
}
