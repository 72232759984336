import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckNullObjectPipe } from './check-null-object.pipe';
import { CheckFieldPipe } from './check-field.pipe';
import { FindInArrayPipe } from './find-in-array.pipe';
import { FindIndexInArrayPipe } from './find-index-array.pipe';



@NgModule({
    declarations: [
        CheckNullObjectPipe,
        CheckFieldPipe,
        FindInArrayPipe,
        FindIndexInArrayPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CheckNullObjectPipe,
        CheckFieldPipe,
        FindInArrayPipe,
        FindIndexInArrayPipe
    ]
})
export class PipesModule { }
